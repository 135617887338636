<nav class="navbar navbar-expand-lg header">
  <div class="container -fluid compress">
    <a class="navbar-brand" routerLink="/">
      <img
        src="https://www.viajesmundomania.com/images/web/logo_vm.png"
        class="logo-img"
        alt=""
        loading="lazy"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed"
      [attr.aria-expanded]="!isMenuCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            class="nav-link text-white text-center"
            routerLink="/"
            (click)="isMenuCollapsed = true"
            ><fa-icon [icon]="faHome"></fa-icon> Ofertas
            <span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white text-center"
            routerLink="/opiniones"
            (click)="isMenuCollapsed = true"
            ><fa-icon [icon]="faComments"></fa-icon> Opiniones</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white text-center"
            routerLink="/contactos"
            (click)="isMenuCollapsed = true"
            ><fa-icon [icon]="faEnvelope"></fa-icon> Contacto</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white text-center"
            routerLink="/puntos"
            (click)="isMenuCollapsed = true"
            ><fa-icon [icon]="faGift"></fa-icon> Puntos</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white text-center"
            routerLink="/login"
            (click)="isMenuCollapsed = true"
            ><fa-icon [icon]="faUser"></fa-icon> Login</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
