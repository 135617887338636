<div class="container-fluid footer py-4 mt-4 d-flex justify-content-center">
  <div class="row justify-content-center align-items-center max-500">
    <div
      class="
        col-xs-12 col-sm-8 col-md-5 col-lg-4
        footer-adapt
        text-blue-bold
        pb-4
      "
    >
      <p class="mb-0">¿Dudas? Contáctanos</p>
      <p class="lead">966 31 00 40</p>
      <p class="mb-0">De lunes a viernes de 10h:00 a 21h:00</p>
      <p>sábados de 10h:00 a 14h:00</p>
    </div>
    <div class="col-xs-12 col-sm-8 col-md-5 col-lg-4 pb-4 footer-adapt">
      <p class="mb-1">
        Viajes Mundomania S.L. 2019® - Avenida Camilo Jose Cela nº 6 , Elda |
      </p>
      <a class="mailto" href="mailto:reservasmundomania@gmail.com"
        >reservasmundomania@gmail.com</a
      >
      <a
        class="btn btn-link facebook-follow pl-0"
        href="https://www.facebook.com/viajesmundomania"
        target="blank"
        >SÍGUENOS EN FACEBOOK <fa-icon [icon]="faArrowRight"></fa-icon
      ></a>
      <iframe
        src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fviajesmundomania%2F%3Fref%3Dpage_internal&width=174&layout=button_count&action=like&size=large&share=true&height=46&appId"
        width="174"
        height="46"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
    <div class="col-xs-12 col-lg-4 pb-4 footer-adapt p-0 align-items-center">
      <div class="d-flex justify-content-center align-items-center">
        <ul class="nav">
          <li class="nav-item">
            <a class="text-secondary mr-1" routerLink="/puntos">Puntos | </a>
          </li>
          <li class="nav-item">
            <a class="text-secondary" routerLink="/privacidad">
              Términos de servicio |
            </a>
          </li>
          <li class="nav-item">
            <a class="text-secondary ml-1" routerLink="/legal"> Aviso legal</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
