import { endpoints } from './endpoints';

export const environment = {
  name: 'dev',
  production: false,
  api: {
    base: 'https://api-front.viajesmundomania.com/api',
    endpoints,
  },
};
