import { Component, OnInit } from '@angular/core';
import { faHome, faComments, faEnvelope, faGift, faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  faHome = faHome;
  faComments = faComments;
  faEnvelope = faEnvelope;
  faGift = faGift;
  faUser = faUser;

  public isMenuCollapsed = true;
  
  constructor() {}

  ngOnInit(): void {}
}
