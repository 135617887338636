import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'opiniones',
    loadChildren: () =>
      import('./modules/opinions/opinions.module').then(
        (m) => m.OpinionsModule
      ),
  },
  {
    path: 'contactos',
    loadChildren: () =>
      import('./modules/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'puntos',
    loadChildren: () =>
      import('./modules/points/points.module').then((m) => m.PointsModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'regristrate',
    loadChildren: () =>
      import('./modules/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./modules/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'privacidad',
    loadChildren: () =>
      import('./modules/privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'password-recovery',
    loadChildren: () =>
      import('./modules/password-recovery/password-recovery.module').then(
        (m) => m.PasswordRecoveryModule
      ),
  },
  {
    path: 'reservas',
    loadChildren: () =>
      import('./modules/booking/booking.module').then((m) => m.BookingModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./modules/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
